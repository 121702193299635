import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '../../../router'

import { getErrorMessage } from '../../../utils/errors'

import UserController from '@/controllers/user.controller'

const userController = new UserController()

export default function () {
  const toast = useToast()

  const setPasswordValidation = ref(null)

  const password = ref('')
  const r_password = ref('')

  const loading = ref(false)

  /**
   * Shows an error toast on screen.
   *
   * @param {Object} error the error object to be shown.
   */
  const showError = (error) => {
    toast({
      component: ToastificationContent,
      props: {
        title: getErrorMessage(error),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  /**
   * Set user password.
   */
  const setPassword = async () => {
    loading.value = true

    const success = await setPasswordValidation.value.validate()

    if (!success) {
      loading.value = false
      return
    }

    const payload = {
      password: password.value,
      r_password: r_password.value,
    }
    
    const params = new URLSearchParams(window.location.search)

    const result = await userController.setPassword(payload, params.get('token'))

    const error = result ? result.error : { statusCode: 500 }

    if (error) {
      loading.value = false
      showError(error)
      return
    }

    loading.value = false

    toast({
      component: ToastificationContent,
      props: {
        title: 'Password successfully defined',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    router.push('/login')
  }

  return {
    setPassword,
    setPasswordValidation,
    password,
    r_password,
    loading,
  }
}

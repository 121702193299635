<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">Asa Pettersson</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid alt="Login V2" :src="imgUrl" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{$t('resetPassword.title')}}
          </b-card-title>
          <b-card-text class="mb-2">
            {{$t('resetPassword.description')}}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="setPasswordValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- password -->
              <b-form-group :label="$t('resetPassword.inputs.label')" label-for="password">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('resetPassword.inputs.label')"
                    rules="required|min:8"
                >
                    <b-form-input
                        v-model="password"
                        type="password"
                        class="form-group"
                        placeholder="············"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group :label="$t('resetPassword.inputs.confirmLabel')" label-for="r_password">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('resetPassword.inputs.confirmLabel')"
                  rules="required|min:8"
                >
                    <b-form-input
                        v-model="r_password"
                        type="password"
                        class="form-group"
                        placeholder="············"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button block type="submit" variant="primary" @click="setPassword">
                <b-spinner v-if="loading" small :label="$t('commons.loading')" />

                <span v-else>{{$t('resetPassword.setNewPassword')}}</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import { required, min } from '@validations'

import store from '@/store'

import useSetPassword from './useSetPassword'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/undraw-my-password.svg'),
      // validation rulesimport store from '@/store/index'
      required
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/undraw-my-password.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const { setPassword, setPasswordValidation, password, r_password, loading } =
      useSetPassword()

    return {
      setPassword,
      setPasswordValidation,
      password,
      r_password,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
